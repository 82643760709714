<template>
  <b-card-code no-body>
    <b-card-body>
      <b-form-group
        label-cols-sm="10"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-2"
      >
        <b-input-group size="sm">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="输入搜索内容"
          />
        </b-input-group>
      </b-form-group>

      <b-table
        responsive
        class="mb-0"
        :items="items"
        :fields="fields"
        :striped="striped"
        :bordered="bordered"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
      >
        <template #cell(TrackNameEN)="data">
          <h-link
            :id="data.item.Tid"
            :text="data.item.TrackNameEN"
            to="track"
            type="track"
          />
        </template>

        <template #cell(Exp)="data">
          <b-avatar
            rounded
            :variant="getExpAvatar(data.item.Exp)"
          >
            <span>{{ Math.floor(data.item.Exp / 100) + 1 }}</span>
          </b-avatar>
        </template>

        <template #cell(Drivelicense)="data">
          <img :src="require(`../../assets/images/license/${data.item.Drivelicense.toUpperCase()}-24.png`)">
        </template>

        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap">

        <b-form-group
          label="每页显示："
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

    </b-card-body>
  </b-card-code>
</template>

<script>
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BCardBody, BFormSelect, BPagination, BFormGroup, BInputGroup,
  BFormInput, BInputGroupPrepend, BAvatar,
} from 'bootstrap-vue'
import axios from '@axios'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCardCode,
    BTable,
    BCardBody,
    BFormSelect,
    BPagination,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BAvatar,
    HLink,
  },
  data() {
    return {
      fields: [
        { key: 'TrackNameEN', label: '赛道名称', sortable: true },
        { key: 'TrackLayout', label: '布局' },
        { key: 'TrackNameAbbr', label: '简称', sortable: true },
        { key: 'TrackNameZH', label: '中文名称', sortable: true },
        { key: 'Country', label: '国家', sortable: true },
        { key: 'Continent', label: '大洲', sortable: true },
        { key: 'Length', label: '长度', sortable: true },
        { key: 'LeftCorners', label: '左弯', sortable: true },
        { key: 'RightCorners', label: '右弯', sortable: true },
        { key: 'RaceCount', label: '举办赛事', sortable: true },
      ],
      items: [],
      striped: true,
      bordered: true,
      pageOptions: [20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    axios.get(Vue.prototype.$Api_Tracks_All)
      .then(response => {
        this.items = response.data
        this.totalRows = this.items.length
      })
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    getExpAvatar(exp) {
      return this.$getExpAvatar(exp)
    },
  },
}
</script>
